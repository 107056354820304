<template>
  <div>
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3"> {{ $t("mediaKindListPage.pageTitle") }}</strong>
            <CButton color="info" @click="onCreateClicked()">{{
              $t("common.create")
            }}</CButton>
          </CCardHeader>
          <CCardBody>
            <!-- #region Table -->
            <table
              class="edge-type-table table table-sm table-striped table-bordered"
            >
              <thead class="bg-light text-body text-center">
                <tr>
                  <th>{{ $t("mediaKindListPage.name") }}</th>
                  <th width="120">{{ $t("mediaKindListPage.isValid") }}</th>
                  <th width="120">{{ $t("mediaKindListPage.action") }}</th>
                </tr>
              </thead>

              <Draggable
                class="table-row-drag"
                v-model="tableItems"
                @change="onSortEnd"
                v-bind="dragOptions"
                v-if="canShowTable"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                  tag="tbody"
                >
                  <tr v-for="item in tableItems" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td class="text-center">
                      <CSwitch
                        class="swc sw-2"
                        color="info"
                        data-on="On"
                        data-off="Off"
                        variant="opposite"
                        :labelOn="$t('common.valid')"
                        :labelOff="$t('common.invalid')"
                        :checked="item.status"
                        @update:checked="onStatusChanged(item)"
                      />
                    </td>
                    <td class="text-center">
                      <CButton
                        class=""
                        color="info"
                        square
                        size="sm"
                        @click="onEditClicked(item.id)"
                        ><i class="icon cil-pencil mr-1"></i
                        >{{ $t("common.edit") }}</CButton
                      >
                    </td>
                  </tr>
                </transition-group>
              </Draggable>
            </table>
            <!-- #endregion Table -->
          </CCardBody>
          <transition name="fade">
              <CRow v-if="mediaKindNotFound">
                <CCol>
                  <div class="d-flex justify-content-center">
                    <CAlert
                      color="danger"
                      class="w-50 p-4 text-center"
                      ><h5>{{ $t("mediaKindListPage.notfound") }}</h5></CAlert>
                  </div>
                </CCol>
              </CRow>
          </transition>
        </CCard>
      </CCol>
    </CRow>

    <SuccessModal :successModal="successModal" @close="successModal = $event" />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
import i18n from "@/i18n";
// import axios from "axios";

import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

const MEDIAKIND_URL = `/rest/imagecategory`;
const PAGE_URL = `/admin/mediaKind`;

export default {
  name: "MediaKindList",
  url: MEDIAKIND_URL,

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true;
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },

    /** drag and drop */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    type() {
      // NOTE:クエリの値はstringなので変換
      return Number(this.$route.query.type);
    },
  },
  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      mediaKindNotFound:false,
      //#endregion Flag
      
      selectedType: 14,
      // //#region Message
      errorMessage: "",
      // //#endregion Message

      //#region table
      /** 一覧列名 */
      tableFields: [
        {
          key: "name",
          label: `${i18n.t("mediaKindListPage.name")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "status",
          label: `${i18n.t("mediaKindListPage.isValid")}`,
          _classes: "text-center bg-light",
          _style: "width: 10%",
        },
        {
          key: "action",
          label: `${i18n.t("mediaKindListPage.action")}`,
          _classes: "text-center bg-light",
          _style: "width: 10%",
        },
      ],
      

      /** 一覧データ */
      tableItems: [],
      //#endregion table

      //#region Response data
      response: [],
      //#endregion Response data
    };
  },

  validations() {
    return {
      edit: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {
    //#region Event

    /** 有効無効切替 */
    onStatusChanged(item) {
      this.reqPutStatus(item);
    },

    /** 編集ボタン押下 */
    onEditClicked(id) {
      this.$router.push({
        path: `${PAGE_URL}/${id}/edit`
      });
    },

    /** 新規作成ボタン押下 */
    onCreateClicked() {
      this.$router.push({
        path: `${PAGE_URL}/create`
      });
    },

    /** 一覧表ソート(並び替え) */
    // TODO:開発中
    onSortEnd(event) {
      // NOTE:イベントが起きたら起動するための引数で処理で使用しない為ここで定義
      event;

      const order = [];
      this.tableItems.forEach((a)=>{
        order.push(a.id)
      })

      // 配列内を番号順にソート
      order.sort((a, b) => a - b);

      // リスト表示している項目に番号を採番
      this.tableItems.forEach((a, i) => {
          this.tableItems[i].displayOrder = order[i];
      });
      this.reqPutDisplayOrder();
    },

    //#endregion Event

    //#region Request
    reqGet() {

      this.mediaKindNotFound = false;
      this.tableItems = [];

      const callback = (a) => {
        const datas = a.categories;
          this.response = datas;
          // 取得データを一覧表に流し込み
          this.pourTable(this.response);
          this.loading = false;
      };

      const errorCallback = (err) => {
        this.mediaKindNotFound = true;
        this.errorMessage = `${err.name} : ${err.message}`;
        this.PassError = err.response.status;
        this.loading = false;
      };

      this.reqGetImageCategoryAll(callback,errorCallback);
    },

    /** 有効無効ステータス更新 */
    reqPutStatus(item){
      const body = { status: item.status };
      const callback = (a) => {
        const response = a;
        this.tableItems = new Array(response);
      };

      const errorCallback = (err) => {
        this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
      };

      this.reqPutImageCategoryStatus(item.id,body,callback,errorCallback);
    },

    /** 表示順更新 */
    reqPutDisplayOrder(){
      const body = { list: [] };
      this.tableItems.forEach((a) => {
        body.list.push({ id: a.id, displayOrder: a.displayOrder });
      });

      const callback = () => this.reqGet();

      const errorCallback = (err) => {
        this.errorModal = true;
        this.errorMessage = `${err.name} : ${err.message}`;
      };

      this.reqPutImageCategoryOrder(body,callback,errorCallback);
    },


    //#endregion Request

    //#region Method

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          id: data.id,
          name: data.name,
          status: data.status,
          // NOTE:APIへはdisplayOrderで送る必要あり
          displayOrder: data.order ?? data.id,
        });
      });
      // console.log(this.tableItems);
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    backPage() {
      this.$router.go(-1);
    },

    //#endregion Method
  },
  beforeMount() {
    this.selectedType = Number(this.$route.query.selectedEnabled);
    this.reqGet();
  },
};
</script>